import React from "react"
import { withTranslation } from "react-i18next"
import img from "../../../../assets/img/media/industries/smarthome/smartdiagram.png"
import img2 from "../../../../assets/img/media/industries/smarthome/diagrammoblie.png"
import img3 from "../../../../assets/img/media/industries/smarthome/smartdiagramVI.png"
import img4 from "../../../../assets/img/media/industries/smarthome/diagrammoblieVI.png"
import "./smarthomediagram.scss"
const SmarthomeDiagram = props => {
  const { i18n } = props
  return (
    <div
      style={{ textAlign: "center", backgroundColor: "#F9FAFC" }}
      className="smartdiagram"
    >
      <img
        src={i18n.language === "en" ? img : img3}
        alt="img"
        className="diagram"
      />
      <img
        src={i18n.language === "en" ? img2 : img4}
        alt="img"
        className="diagramMobile"
      />
    </div>
  )
}
export default withTranslation()(SmarthomeDiagram)
