import React from "react"
import { withTranslation } from "react-i18next"
import "./smarthomeIcon.scss"
import phone from "../../../../assets/img/media/industries/smarthome/phone.png"
import clound from "../../../../assets/img/media/industries/smarthome/clound.png"
import leaf from "../../../../assets/img/media/industries/smarthome/leaf.png"
import surface1 from "../../../../assets/img/media/industries/smarthome/surface1.png"
const SmarthomeIcon = props => {
  const { t } = props
  return (
    <>
      <div className="smarthomeIcon">
        <div className="smart-icon">
          <img src={phone} alt="phone" />
          <p>{t("src.pages.smarthomePage.iconRemote")}</p>
        </div>
        <div className="smart-icon">
          <img src={clound} alt="phone" />
          <p>{t("src.pages.smarthomePage.iconCloud")}</p>
        </div>
        <div className="smart-icon">
          <img src={leaf} alt="phone" />
          <p>{t("src.pages.smarthomePage.iconLeaf")}</p>
        </div>
        <div className="smart-icon">
          <img src={surface1} alt="phone" />
          <p>{t("src.pages.smarthomePage.iconRepair")}</p>
        </div>
      </div>

      <div className="smarthomeIconMobile">
        <div className="block1">
          <div className="smart-icon">
            <img src={phone} alt="phone" className="phones" />
            <p>{t("src.pages.smarthomePage.iconRemote")}</p>
          </div>
          <div className="smart-icon">
            <img src={clound} alt="phone" className="clound" />
            <p>{t("src.pages.smarthomePage.iconCloud")}</p>
          </div>
        </div>
        <div className="block2">
          <div className="smart-icon">
            <img src={leaf} alt="phone" />
            <p>{t("src.pages.smarthomePage.iconLeaf")}</p>
          </div>
          <div className="smart-icon">
            <img src={surface1} alt="phone" />
            <p>{t("src.pages.smarthomePage.iconRepair")}</p>
          </div>
        </div>
      </div>
    </>
  )
}
export default withTranslation()(SmarthomeIcon)
