import React from "react"
// import {dataSmarthome} from './../../../../data/Industries/dataSmarthome'
import "./smarthomeTitle.scss"
import { withTranslation } from "react-i18next"

const ConstructionPageTitle = props => {
  const { t } = props
  return (
    <section className="headerIndustrie bg-smarthome">
      <div className="smarthome__container">
        <div className="hero__content">
          <h1 className="titlesmarthome title--margin-top white-text">
            {t("src.pages.smarthomePage.title1")}
          </h1>
          <h1 className="titlesmarthome title--margin-top white-text">
            {t("src.pages.smarthomePage.title2")}
          </h1>
          <p className="header__lead">
            {t("src.pages.smarthomePage.descriptions")}
          </p>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(ConstructionPageTitle)
