import React from "react"
import Layout from "../../layout/layout.higherComponent"
import Head from "../../head"
// import HealthcarePageTilte from './healthcasePageTitle/healthcasePageTitle'
// import PatientEngagement from './patientEngagement/patientEngagement'
// import EmployeeManagement from './employeeManagement/employeeManagement'
// import AnalyticsAndAdministration from './analyticsAndAdministration/analyticsAndAdministration'
// import ClientSuccess from './clientSuccess/clientSuccess'
// import Contact from './contact/contact'
import Smarthometitle from './smarthomeTitle/smarthomeTitle'
import SmarthomeSystem from "./smarthomeSystem/smarthomeSystem"
import SmarthomeDiagram from "./smarthomediagram/smarthomediagram"
import AcexisSmart from "./acexissmarthome/acexissmarthome"
import SmarthomeBg from "./smarthomeBg/smarthomeBg"
import SmarthomeIcon from "./smarthomeIcon/smarthomeIcon"
import TitleMobile from "./smartTitleMoblie/smartTitleMobile"
const HealthcarePage = () => {
  return (
    <>
      <Layout displayHeader={false}>
        <Head title="Smarthome" />
        <Smarthometitle />
        <TitleMobile />
        <SmarthomeSystem />
        <SmarthomeDiagram />
        <AcexisSmart />
        <SmarthomeBg />
        <SmarthomeIcon />
        {/* <HealthcarePageTilte />
        <PatientEngagement />
        <EmployeeManagement />
        <AnalyticsAndAdministration />
        <ClientSuccess />
        <Contact /> */}
      </Layout>
    </>
  )
}

export default HealthcarePage
