import React from "react"
import { withTranslation } from "react-i18next"
import "./smarthomeBg.scss"
import img1 from "../../../../assets/img/media/industries/smarthome/CO1.png"
import img2 from "../../../../assets/img/media/industries/smarthome/Layer1.png"
import img3 from "../../../../assets/img/media/industries/smarthome/water.png"
import img4 from "../../../../assets/img/media/industries/smarthome/kitchen.png"

const SmarthomeBg = props => {
  const { t } = props
  return (
    <div className="smarthomeBg">
      <div className="bg">
        <img src={img1} alt="img" />
        <div className="bg-content content1">
          <p className="bg-title">{t("src.pages.smarthomePage.bgCOTitle")}</p>
          <p className="content">{t("src.pages.smarthomePage.bgCOContent")}</p>
        </div>
      </div>
      <div className="bg">
        <img src={img2} alt="img" />
        <div className="bg-content content2">
          <p className="bg-title">{t("src.pages.smarthomePage.bgGasTitle")}</p>
          <p className="content">{t("src.pages.smarthomePage.bgGasContent")}</p>
        </div>
      </div>
      <div className="bg">
        <img src={img3} alt="img" />
        <div className="bg-content content3">
          <p className="bg-title">
            {t("src.pages.smarthomePage.bgWaterTitle")}
          </p>
          <p className="content">
            {t("src.pages.smarthomePage.bgWaterContent")}
          </p>
        </div>
      </div>
      <div className="bg">
        <img src={img4} alt="img" />
      </div>
    </div>
  )
}
export default withTranslation()(SmarthomeBg)
