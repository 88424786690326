import React from "react"
import "./smartTitleMobile.scss"
// import { dataSmarthome } from './../../../../data/Industries/dataSmarthome'
import { withTranslation } from "react-i18next"

const TitleMobile = props => {
  const { t } = props
  return (
    <div className="titleMobile">
      <p>{t("src.pages.smarthomePage.descriptions")}</p>
    </div>
  )
}
export default withTranslation()(TitleMobile)
