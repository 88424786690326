import React from "react"
import { withTranslation } from "react-i18next"
import "./smarthomeSystem.scss"
import img1 from "../../../../assets/img/media/industries/smarthome/Layer3.png"
import img2 from "../../../../assets/img/media/industries/smarthome/CO.png"
import img3 from "../../../../assets/img/media/industries/smarthome/smarthome_system.png"
import img4 from "../../../../assets/img/media/industries/smarthome/Layer2.png"
import img5 from "../../../../assets/img/media/industries/smarthome/ThermalStart.png"
import img6 from "../../../../assets/img/media/industries/smarthome/Dusun.png"
import img7 from "../../../../assets/img/media/industries/smarthome/ThermalStart2.png"
import img8 from "../../../../assets/img/media/industries/smarthome/smarthome_system2.png"

const SmarthomeSystem = props => {
  const { t } = props
  return (
    <>
      <div className="smarthomeSystem">
        <div className="block1">
          <div className="blockleft">
            <div className="d-flex">
              <div className="block-img">
                <img src={img1} alt="img" />
                <p>{t("src.pages.smarthomePage.smartBulb")}</p>
              </div>
              <div className="block-img">
                <img src={img2} alt="img" />
                <p>{t("src.pages.smarthomePage.air")}</p>
              </div>
            </div>
            <div className="block-img">
              <img src={img4} alt="img" />
              <p>{t("src.pages.smarthomePage.gas")}</p>
            </div>
          </div>
          <div className="blockright">
            <div className="block-img">
              <img src={img3} alt="img" />
              <p>{t("src.pages.smarthomePage.system")}</p>
            </div>
          </div>
        </div>
        <div className="block2">
          <div className="block-img">
            <img src={img5} alt="img" />
            <p>{t("src.pages.smarthomePage.thermalStat")}</p>
          </div>
          <div className="block-img img__mobile">
              <img src={img3} alt="img" />
              <p>{t("src.pages.smarthomePage.system")}</p>
          </div>
          <div className="block-img">
            <img src={img6} alt="img" />
            <p>{t("src.pages.smarthomePage.kitchen")}</p>
          </div>
        </div>
      </div>

      {/* <div className="smarthomeSystemMoblie">
        <div className="block1">
          <div className="blockleft">
            <div className="d-flex">
              <div className="block-img">
                <img src={img1} alt="img" />
                <p>{t("src.pages.smarthomePage.smartBulb")}</p>
              </div>
              <div className="block-img">
                <img src={img2} alt="img" />
                <p>{t("src.pages.smarthomePage.air")}</p>
              </div>
            </div>
            <div className="block-img">
              <img src={img4} alt="img" />
              <p>{t("src.pages.smarthomePage.gas")}</p>
            </div>
          </div>
        </div>
        <div className="block2">
          <div className="block-img">
            <img src={img7} alt="img" />
            <p>{t("src.pages.smarthomePage.thermalStat")}</p>
          </div>
          <div className="block-img">
            <img src={img8} alt="img" />
            <p>{t("src.pages.smarthomePage.system")}</p>
          </div>
        </div>
        <div className="block3">
          <div className="block-img" style={{ margin: "0 10px" }}>
            <img src={img6} alt="img" />
            <p>{t("src.pages.smarthomePage.kitchen")}</p>
          </div>
        </div>
      </div> */}
    </>
  )
}
export default withTranslation()(SmarthomeSystem)
