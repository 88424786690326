import React from "react"
import HealthcarePage from "../components/industries/smarthome/smarthomePage"


const Healthcare = () => {
  return (
    <>
      <HealthcarePage />
    </>
  )
}

export default Healthcare
