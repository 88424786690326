import React from "react"
import { withTranslation } from "react-i18next"
import "./acexissmarthome.scss"

const AcexisSmart = props => {
  const { t } = props
  return (
    <div className="acexis_smart">
      <p className="acexis_title">
        {t("src.pages.smarthomePage.smartHomeTitle")}
      </p>
      <p className="acexis_content">
        {" "}
        {t("src.pages.smarthomePage.smartHomeContent")}
      </p>
    </div>
  )
}
export default withTranslation()(AcexisSmart)
